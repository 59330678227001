import { lazy, Suspense } from 'react';
// layouts
import CompactLayout from 'src/layouts/compact';
import AuthModernLayout from 'src/layouts/auth/modern';
import AuthClassicLayout from 'src/layouts/auth/classic';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';
// components
import { SplashScreen } from 'src/components/loading-screen';
import AnalyticsOutlet from './analytics-outlet';

// ----------------------------------------------------------------------

// CLASSIC
const LoginClassicPage = lazy(() => import('src/pages/auth-demo/classic/login'));
const RegisterClassicPage = lazy(() => import('src/pages/auth-demo/classic/register'));
const ForgotPasswordClassicPage = lazy(() => import('src/pages/auth-demo/classic/forgot-password'));
const VerifyClassicPage = lazy(() => import('src/pages/auth-demo/classic/verify'));
const NewPasswordClassicPage = lazy(() => import('src/pages/auth-demo/classic/new-password'));

// MODERN
const LoginModernPage = lazy(() => import('src/pages/auth-demo/modern/login'));
const RegisterModernPage = lazy(() => import('src/pages/auth-demo/modern/register'));
const ForgotPasswordModernPage = lazy(() => import('src/pages/auth-demo/modern/forgot-password'));
const VerifyModernPage = lazy(() => import('src/pages/auth-demo/modern/verify'));
const NewPasswordModernPage = lazy(() => import('src/pages/auth-demo/modern/new-password'));

// ----------------------------------------------------------------------

const authClassic = {
  // path: 'classic',
  // element: (
  //   <Suspense fallback={<SplashScreen />}>
  //     <Outlet />
  //   </Suspense>
  // ),
  // children: [
  // {
  //   path: 'login',
  //   element: (
  //     <AuthClassicLayout>
  //       <LoginClassicPage />
  //     </AuthClassicLayout>
  //   ),
  // },
  // {
  //   path: 'register',
  //   element: (
  //     <AuthClassicLayout title="Manage the job more effectively with Minimal">
  //       <RegisterClassicPage />
  //     </AuthClassicLayout>
  //   ),
  // },
  // {
  element: (
    <Suspense fallback={<SplashScreen />}>
      <CompactLayout>
        <AnalyticsOutlet />
      </CompactLayout>
    </Suspense>
  ),
  children: [
    // { path: 'forgot-password', element: <ForgotPasswordClassicPage /> },
    { path: 'new-password', element: <NewPasswordClassicPage /> },
    // { path: 'verify', element: <VerifyClassicPage /> },
  ],
  // },
  // ],
};

const authModern = {
  path: 'modern',
  element: (
    <Suspense fallback={<SplashScreen />}>
      <AnalyticsOutlet />
    </Suspense>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthModernLayout>
          <LoginModernPage />
        </AuthModernLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthModernLayout>
          <RegisterModernPage />
        </AuthModernLayout>
      ),
    },
    {
      element: (
        <AuthModernCompactLayout>
          <AnalyticsOutlet />
        </AuthModernCompactLayout>
      ),
      children: [
        { path: 'forgot-password', element: <ForgotPasswordModernPage /> },
        { path: 'new-password', element: <NewPasswordModernPage /> },
        { path: 'verify', element: <VerifyModernPage /> },
      ],
    },
  ],
};

export const authDemoRoutes = [
  {
    path: 'auth',
    children: [authClassic],
    // children: [authClassic, authModern],
  },
];
