import React, { useContext, useState } from 'react';
// import { useState } from 'react'; // Import useState
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip'; // Import Tooltip
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import { RouterLink } from 'src/routes/components';
import Iconify from 'src/components/iconify';
// auth
import { AuthContext } from 'src/auth/context/firebase/auth-context';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    console.log('Text copied to clipboard');
  } catch (err) {
    console.error('Failed to copy text: ', err);
  }
};

export default function NavUpgrade() {
  // const { user } = useMockedUser();
  const { user } = useContext(AuthContext);

  const { t } = useLocales();

  const theme = useTheme();

  // State to manage Tooltip text and icon
  const [tooltipContent, setTooltipContent] = useState('Copy to clipboard');

  const handleCopyClick = () => {
    copyToClipboard(user.couponName);
    setTooltipContent(
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Iconify icon="solar:check-circle-bold-duotone" width={20} sx={{ mr: 0.5 }} />
        Copied to clipboard
      </Box>
    );
  };

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box sx={{ position: 'relative' }}>
          <Avatar src={user?.photoURL} alt={user?.displayName} sx={{ width: 48, height: 48 }} />
          <Label
            color="success"
            variant="filled"
            sx={{
              top: -6,
              px: 0.5,
              left: 40,
              height: 20,
              position: 'absolute',
              borderBottomLeftRadius: 2,
            }}
          >
            {user.enterprise && <>Admin</>}
            {user.subAdmin && <>Sub-Admin</>}
            {!user.enterprise && !user.employee && !user.subAdmin && <>Individual</>}
            {user.employee && <>Enterprise</>}
          </Label>
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            {user?.email}
          </Typography>
        </Stack>

        <Tooltip title={tooltipContent} arrow>
          <Button
            onClick={handleCopyClick}
            endIcon={<Iconify icon="solar:clipboard-bold-duotone" width={24} sx={{ mr: 0.5 }} />}
            variant="contained"
          >
            Recommend to Others
          </Button>
        </Tooltip>

        <Box
          sx={{
            mt: 2,
            mb: 2,
            // p: 2,
            borderRadius: 2,
            // bgcolor: 'background.paper',
            border: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Typography variant="body2" sx={{ mt: 1, mb: 1, color: 'text.disabled' }}>
            Earn up to
            {/* <Label
              color="success"
              variant="filled"
              sx={{
                // top: -6,
                px: 0.5,
                // left: 40,
                height: 20,
                position: 'relative',
                // borderBottomLeftRadius: 2,
              }}
            > */}
            <strong> 150 € </strong>
            {/* </Label> */}
            for each new customer with your coupon code.
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
}
