import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const AnalyticsOutlet = () => {
  const location = useLocation();

  useEffect(() => {
    // Here you would call your analytics tracking code
    window.gtag('event', 'page_view', {
      page_title: document.title,
      page_path: location.pathname + location.search,
      page_location: window.location.href,
    });
    // Make sure to replace 'gtag' with your Google Analytics tracking function
  }, [location]);

  return <Outlet />;
};

export default AnalyticsOutlet;
